import * as React from "react";
import { Anchor, Box, Button, Heading, Paragraph } from "grommet";

const GenerativeAIPage = () => {
  return (
    <>
      <Heading level={2} style={{color: "#3600b2"}}>Generative AI</Heading>
       <Box>
          <Paragraph fill>
            <strong style={{color:"#3600b2"}}>Encontrar profissionais</strong> especializados e capacitados na área de TI é uma <strong style={{color:"#3600b2"}}>tarefa extremamente complexa e custosa</strong>,
            quando buscamos profissionais com <strong style={{color:"#3600b2"}}>especialização em dados e transformação digital</strong> (arquitetos de dados, engenheiros de dados, 
            cientistas de dados, especialistas em inteligência artificial, especialistas em governança de dados, DPOs, desenvolvedores frontend e/ou backend, 
            entre outros), essa <strong style={{color:"#3600b2"}}>complexidade aumenta exponencialmente</strong>.
          </Paragraph>

          <Paragraph fill>
            Estamos assumindo um papel ativo em ajudar nossos clientes a se beneficiar da <strong style={{color:"#3600b2"}}>IA Generativa</strong>, 
            disponibilizamos nosso time de especialistas nos <strong style={{color:"#3600b2"}}>modelos de linguagem (LLMs)</strong>, para criarem <strong style={{color:"#3600b2"}}>soluções inovadoras 
            e de alto desempenho</strong> que levem seus negócios e produtos ao próximo nível.
          </Paragraph>

          <Paragraph fill>
            Estamos focados em <strong style={{color:"#3600b2"}}>impulsionar a produtividade</strong> nas operações de <strong style={{color:"#3600b2"}}>Tecnologia da Informação</strong> e nos 
            principais <strong style={{color:"#3600b2"}}>Processos de Negócio</strong>, como Recursos Humanos e Marketing, <strong style={{color:"#3600b2"}}>elevando suas experiencias e evoluindo seus modelos de negócios</strong>.
          </Paragraph>

          <Paragraph fill>
          Alguns <strong style={{color:"#3600b2"}}>benefícios</strong> da <strong style={{color:"#3600b2"}}>IA Generativa</strong>:
          </Paragraph>
          <ul>
              <li style={{marginBottom: "10px", fontSize: "1rem"}}>Acelere e Personalize a Geração de Conteúdo.</li>
              <li style={{marginBottom: "10px", fontSize: "1rem"}}>Melhore a Experiencia do Cliente.</li>
              <li style={{marginBottom: "10px", fontSize: "1rem"}}>Economize Custos de Mão de Obra.</li>
          </ul>

          <Paragraph fill>
            Algumas <strong style={{color:"#3600b2"}}>formas de aplicar IA Generativa</strong> em sua organização:
          </Paragraph>
          <ul>
              <li style={{marginBottom: "15px", fontSize: "1rem"}}>Design e Desenvolvimento de Produtos
              <Paragraph fill>
                Utilize a <strong style={{color:"#3600b2"}}>IA Generativa</strong> para automatizar as etapas do processo, como gerar opções de <strong style={{color:"#3600b2"}}>wireframes, criar simulações</strong>, e acelere a entrega dos seus produtos.
              </Paragraph>
              </li>
              <li style={{marginBottom: "15px", fontSize: "1rem"}}>Automação do Ciclo de Vida – Desenvolvimento de Software
              <Paragraph fill>
              <strong style={{color:"#3600b2"}}>Automatize tarefas</strong> integrando a <strong style={{color:"#3600b2"}}>IA Generativa</strong>, ao ciclo de vida de desenvolvimento de sistemas.
              </Paragraph>
              <Paragraph fill>
                É possível <strong style={{color:"#3600b2"}}>gerar código ou otimizar seu desemprenho, analisar dados e gerar insights</strong>, entre outras possibilidades.
              </Paragraph>
              </li>
              <li style={{marginBottom: "15px", fontSize: "1rem"}}>Otimização de Processos de Negócio
              <Paragraph fill>
                Incorpore <strong style={{color:"#3600b2"}}>IA Generativa</strong> para <strong style={{color:"#3600b2"}}>agilizar e otimizar os processos</strong> de negócio, tornando-os mais <strong style={{color:"#3600b2"}}>eficiente e eficazes</strong>.
              </Paragraph>
              <Paragraph fill>
                Melhore a tomada de decisões através de <strong style={{color:"#3600b2"}}>insights</strong> gerados a partir de <strong style={{color:"#3600b2"}}>análise de dados automatizadas</strong>.
              </Paragraph>
              </li>
          </ul>
       </Box>
    </>
  );
};

export default GenerativeAIPage;
